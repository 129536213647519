import React from 'react'

import Layout from '../../components/layout'
import Head from '../../components/head'

import Styxcalligraphy from './styxcalligraphy.jpg'

const StyxcalligraphyPage = () => {
    return (
        <Layout image={Styxcalligraphy} footerImageDescription='"Styx" - Poster Design' >
            <Head title="Styx Calligraphy -"/>
        </Layout>
    )
}

export default StyxcalligraphyPage